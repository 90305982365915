/*global $*/
/*eslint no-undef: "error"*/

export default class SearchButton {
    classBlock = 'search-button--block'
    classBlockHidden = 'page-hidden'
    classButton = 'search-button--button'
    classOpen = 'search-button-open'
    idInput = 'search'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classOpen)
            $(`.${self.classBlock}`).toggleClass(self.classBlockHidden)

            if ($(this).hasClass(self.classOpen)) {
                $(`#${self.idInput}`).focus()
            }
        })
    }
}
