/*global $*/
/*eslint no-undef: "error"*/

export default class LangButton {
    classBlock = 'lang--block'
    classOpen = 'lang-open'
    classButton = 'lang--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            console.log('work')
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classOpen)
        })
    }
}
