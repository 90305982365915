/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import { Fancybox } from '@fancyapps/ui'
import './assets/js/css/ImportCss'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import LangButton from '@/assets/js/Lang/LangButton/LangButton'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import SearchButton from '@/assets/js/Search/Button/SearchButton'
import ButtonTop from '@/assets/js/Button/ButtonTop/ButtonTop'
import form from '@/store/form'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormPopupPhone from '@/components/Form/Popup/FormPopupPhone/AppFormPopupPhone.vue'
import AppFormPopupProduct from '@/components/Form/Popup/FormPopupProduct/AppFormPopupProduct.vue'

const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new SwiperSlider()
    new HeaderMobile()
    new MenuMobile()
    new LangButton()
    new ShowHidden()
    new SearchButton()
    new ButtonTop()

    if(document.getElementById('block--form')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block-form--phone')!= null) {
        createApp({})
            .component('form-popup', AppFormPopupPhone)
            .use(Maska)
            .use(form)
            .mount('#block-form--phone')
    }

    if(document.getElementById('block-form--product')!= null) {
        createApp({})
            .component('form-popup-product', AppFormPopupProduct)
            .use(Maska)
            .use(form)
            .mount('#block-form--product')
    }
})
