/*global $*/
/*eslint no-undef: "error"*/

export default class MenuMobile {
    classButton = 'menu-mobile--button'
    classBlock = 'menu-mobile--block'
    classOpen = 'menu-mobile__item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classOpen)
        })
    }
}
