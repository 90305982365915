import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSlider () {
        return new Swiper('#slider--slider', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createNews () {
        return new Swiper('#slider--news', {
            slidesPerView: 3,
            spaceBetween: 70,
            navigation: {
                nextEl: '.slider--news-next',
                prevEl: '.slider--news-prev',
            },
            pagination: {
                el: '.swiper-pagination--news',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                }
            }
        })
    }

    createPartner () {
        return new Swiper('#slider--partner', {
            slidesPerView: 4,
            spaceBetween: 30,
            navigation: {
                nextEl: '.slider--partner-next',
                prevEl: '.slider--partner-prev',
            },
            pagination: {
                el: '.swiper-pagination--partner',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                900: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--slider')!= null) {
            this.createSlider()
        }

        if(document.getElementById('slider--news')!= null) {
            this.createNews()
        }

        if(document.getElementById('slider--partner')!= null) {
            this.createPartner()
        }
    }
}
